





















import { Vue, Component, Prop } from 'vue-property-decorator'
import Navbar from '../View/Navbar.vue'
import { Environment, EnvironmentUser, User, UserProfile } from '@/models'
import _cloneDeep from 'lodash/cloneDeep'
import _pick from 'lodash/pick'
import _mapKeys from 'lodash/mapKeys'
import Form from '@/components/form/Form.vue'
import Loading from '@/components/Loading.vue'
import gql from 'graphql-tag'
import { ApolloError } from 'apollo-client'

@Component({
  components: {
    Form,
    Navbar,
    Loading
  }
})
export default class EnvironmentManage extends Vue {
  @Prop({ type: Object, required: true }) environment !: Environment
  @Prop({ type: Object, required: true }) environmentUser !: EnvironmentUser
  @Prop({ type: Boolean, default: false }) sidebar !: boolean
  @Prop({ type: Boolean, default: false }) sidebarOpen !: boolean
  @Prop({ type: String, default: '' }) path !: string
  @Prop({ type: [Number, String] }) notificationCount ?: number | string

  get title () {
    switch (this.path.split('/')[2]) {
      case 'users':
        return 'Administración de Usuarios'
      default:
        return 'Administración'
    }
  }
}
